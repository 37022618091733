@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-loader: '#232040';
    --color-primary: '#1600E5';
    --color-heading: '#17191C';
    --color-primary-light: '#F3F2FF';
    --color-primary-mid-light: '#CDC8FF';
    /* ... */
  }
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    @apply text-custom-dark-main;
  }
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f9f9;
  /* overflow-y: visible !important; */
}
.dark body {
  background: #27272a;
}
* {
  box-sizing: border-box;
}

body input:focus-visible {
  outline: none;
}

button,
a {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section-heading {
  @apply text-2xl;
  @apply text-custom-dark-main;
  @apply font-medium;
}

.sc-gsTCUz,
.sc-gsDJrp {
  z-index: 5000;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #babac0;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #929296;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

label.required:after {
  content: ' *';
  color: hsl(0deg 100% 50% / 77%);
}

/* ----------- card view css */

.nav-panel {
  flex-shrink: 0;
  width: 300px;
}

.demo-navigator {
  list-style: none;
  box-sizing: border-box;
  position: fixed;
  width: 300px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  padding: 0px;
  margin: 0;
  box-shadow: 1px 0px 5px #ccc;
  z-index: 1;
}

.demo-navigator li {
  height: 50px;
  display: block;
}

.demo-header {
  height: 50px;
  background-color: #eee;
}

.source-code {
  float: right;
  height: 60px;
  line-height: 60px;
  vertical-align: middle;
  cursor: pointer;
}

.source-code img {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.source-code span {
  font-size: 14px;
  color: #eee;
  margin-left: 10px;
  vertical-align: middle;
}

.demo-navigator button {
  border: 1px solid #348;
  text-align: center;
  height: 100%;
  width: 100%;
  display: block;
  background-color: rgb(64, 105, 182);
  cursor: pointer;
  outline: 0;
  color: white;
  border-top: 1px solid rgb(116, 137, 223);
  border-bottom: 1px solid rgb(43, 76, 185);
  transition: all 0.2s ease;
  font-size: 16px;
}

.demo-navigator button:hover {
  background-color: rgb(47, 94, 182);
}

.simple-page {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.simple-page-scroller {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  overflow-y: auto;
  height: 80vh;
}

.draggable-item {
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2px;
  margin-top: 2px;
}

.draggable-item-horizontal {
  height: 300px;
  padding: 10px;
  line-height: 100px;
  text-align: center;
  /* width : 200px; */
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 4px;
}

.form-demo {
  width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  display: flex;
}

.form {
  flex: 3;
  /* width: 500px; */
  /* background-color: #f3f3f3; */
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.08), 0px 3px 3px rgba(0, 0, 0, 0.08);
}

.form-fields-panel {
  flex: 1;
  margin-right: 50px;
}

.form-field {
  height: 50px;
  width: 250px;
  line-height: 30px;
  vertical-align: middle;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #eee;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ddd;
  /* background-color: #eee; */
  cursor: move;
}

.form-line {
  padding: 20px 30px;
  background-color: #f8f9fa;
  border: 1px solid transparent;
  border-radius: 6px;
  transition: all 0.3s ease;
  transition-property: border-color, background-color;
  cursor: move;
}

.form-line.selected {
  /* border: 1px solid #ddd; */
  background-color: #f8f9fa;
  /* box-shadow: 0px 0px 10px 10px #ccc; */
}

.form-ghost {
  transition: 0.18s ease;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
}

.form-ghost-drop {
  box-shadow: 0 0 2px 5px rgba(0, 0, 0, 0);
}

.form-submit-button {
  border: 0;
  width: 100%;
  vertical-align: middle;
  height: 40px;
  color: #eee;
  background-color: cornflowerblue;
  cursor: pointer;
}

.label {
  margin-bottom: 5px;
}

.field {
  cursor: auto;
}

.field input,
.field textarea,
.field select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: none;
  transition: border-color 0.3s ease;
}

.field input[type='radio'],
.field input[type='checkbox'] {
  width: auto;
}

/* .field input:focus, textarea:focus {
  border: 1px solid #80bdff;
  box-shadow: 0 0 3px 0px #80bdff;
} */

.field-group input,
.field-group textarea,
.field-group select {
  width: 49%;
  margin-right: 2%;
}

input:last-child {
  margin-right: 0;
}

.dragging {
  background-color: yellow;
}

.card-container {
  width: 320px;
  padding: 10px;
  margin: 5px;
  margin-right: 45px;
  background-color: #f3f3f3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
}

.card {
  margin: 5px;
  /* border: 1px solid #ccc; */
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
  padding: 10px;
}

.card-column-header {
  font-size: 18px;
}

.column-drag-handle {
  cursor: move;
  padding: 5px;
}

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}

.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  cursor: ns-resize;
  /* transform: rotateZ(5deg); */
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
  cursor: ns-resize;
}

.opacity-ghost-drop {
  opacity: 1;
  /* transform: rotateZ(0deg); */
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}

.drop-zone {
  display: flex;
  flex-wrap: wrap;
}

.drop-zone-container {
  transition: background-color 0.2s ease;
  width: 100px;
  height: 100px;
  border: 5px solid #348;
  background-color: #80bdff;
  border-radius: 100px;
  margin: 20px;
}

.drop-zone-container.hover {
  background-color: yellow;
  transform: scale(1.02);
}

.drop-zone-draggable {
  width: 80px;
  height: 80px;
  border: 2px solid #348;
  background-color: cornflowerblue;
  border-radius: 100px;
  margin: 10px;
}

.board {
  width: 800px;
  height: 800px;
  border: 1px solid #000;
}

.row {
  display: flex;
}

.square {
  width: 100px;
  height: 100px;
  background-color: #ffce9e;
}

.square.black {
  background-color: #d18b47;
}

.piece {
  width: 100px;
  height: 100px;
  font-size: 60px;
  padding-left: 20px;
  padding-top: 10px;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.piece.hover {
  opacity: 0.5;
}

.piece-img {
  width: 100px;
  height: 100px;
  vertical-align: middle;
}

.dynamic-container-holder {
  width: 300px;
}

.popup-container {
  position: absolute;
  overflow-y: auto;
  z-index: 20;
  height: 700px;
  display: none;
  border: 1px solid #aaa;
  box-shadow: 2px 2px 10px 1px #ccc;
  background-color: #fff;
  margin-top: 30px;
  margin-left: 10px;
}

.popup-container.open {
  display: block;
}

.popup-container-button {
  display: flex;
  justify-content: center;
  width: 250px;
  padding: 15px;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: peachpuff;
  margin-right: 10px;
}

.dynamic-left-pane {
  width: 300px;
  padding: 50px;
  overflow-y: auto;
}

.dynamic-right-pane {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fefefe;
}

.dynamic-menu-container {
  padding: 10px 0;
  padding-left: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #eee;
  display: flex;
  box-shadow: 2px 2px 10px 1px #ccc;
}

.dynamic-right-content {
  flex: 1;
  overflow-y: auto;
  padding: 50px;
}

.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}

.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px 45px 5px 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.manual_time::placeholder {
  color: var(--color-primary-light);
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MultiColorIcon-path--white {
  fill: rgba(21, 7, 38, 0.25);
}

.selected-icon .MultiColorIcon-path--white {
  fill: #fff;
}

/* .template {
  outline: none;
} */

.template:focus {
  outline: 2px solid var(--color-primary);
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
