.avatar__active::after {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  right: -2px;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #44b700;
  border: 0.1em solid #fff;
  z-index: 9;
}

.avatar__tiny {
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding-left: 1px;
  font-size: 9px;
  letter-spacing: 1px;
}

.avatar__small {
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding-left: 1px;
  font-size: 10px;
  letter-spacing: 1px;
}

.avatar__medium {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 10px;
}

.avatar__normal {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.avatar__large {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}

.avatar__xl {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 22px;
}

.avatar__full {
  width: 100%;
  height: 100%;
}

/* @media (min-width: 1684px) {
  .avatar__small {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .avatar__medium {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 12px;
  }

  .avatar__normal {
    width: 38px;
    height: 38px;
    line-height: 40px;
    font-size: 17px;
  }
} */
