.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .beamer_defaultBeamerSelector {
  display: none;
} */

.section-loader span {
  width: 15px;
  height: 15px;
  animation: scale-up 1s ease-in-out infinite;
}

.section-loader span:nth-child(2) {
  animation-delay: 0.2s;
}

.section-loader span:nth-child(3) {
  animation-delay: 0.4s;
}

.react-datepicker-popper {
  /* overwriting date-picker z-index */
  z-index: 999 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

@keyframes scale-up {
  50% {
    transform: scale(0.75, 2);
  }
}

::-ms-reveal {
  display: none !important;
  /*hiding edge's default eye icon from the password field*/
}

@media print {
  .invoice-print {
    box-shadow: none !important;
    border: none !important;
  }
}

/* ============ CK EDITOR ================ */
.ck-editor__editable {
  height: auto;
  overflow: auto;
  /* --ck-color-focus-border: #1600e5; */
  --ck-focus-ring: 1px solid var(--color-primary);
}

.ck-sticky-panel__placeholder {
  display: none !important;
}

.ck-sticky-panel__content_sticky {
  position: relative !important;
}

/* ============= Utils Classes =========== */
.show-on-hover-child {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  visibility: hidden;
}

.show-on-hover-parent:hover .show-on-hover-child {
  opacity: 1;
  visibility: visible;
}

.break-long-word {
  word-break: break-word;
}

input[type='date'].white-icon::-webkit-calendar-picker-indicator {
  filter: invert(1);
  width: 15px;
  height: 15px;
}

input[type='time'].hide-time-icon::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

/* ========= react grid layout fix ========== */
.react-grid-layout {
  position: relative;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 2px;
  bottom: 2px;
  width: 15px !important;
  height: 15px !important;
  border-right: 6px solid var(--color-primary) !important;
  border-bottom: 6px solid var(--color-primary) !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 18px !important;
  right: -5px !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 18px !important;
  left: -5px !important;
}

/* ===== Project calendar view ===== */
/* .App__project-calendar-view .rbc-month-view {
  border-left: 1px solid black;
} */
.App__project-calendar-view .rbc-header {
  padding: 8px 8px;
  font-size: 13px;
  font-weight: 600;
  color: '#242236';
  text-align: right;
  /* border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black; */
}

.App__project-calendar-view .rbc-button-link {
  padding: 2px 4px;
  font-size: 13px;
  color: '#242236';
  font-weight: 600;
  border-radius: 5px;
}

.App__project-calendar-view .rbc-button-link:hover {
  background-color: rgb(245, 241, 241);
}

.App__project-calendar-view .rbc-now > .rbc-button-link {
  color: white;
  background: rgb(0, 128, 255);
}

/* .App__project-calendar-view .rbc-today {
  background: #fff;
} */
.App__project-calendar-view .rbc-off-range-bg {
  background-color: transparent;
}

.ql-editor a {
  cursor: pointer;
}

.ql-snow .ql-editor img {
  max-width: 400px;
}

.list-view-description .ql-container.ql-snow {
  border: none;
  max-height: 250px;
  overflow: auto;
}

.ql-snow .ql-tooltip {
  top: 0 !important;
}

/* .ql-tooltip {
  left: 0 !important;
} */

/* .fc-widget-small {
left: 15px !important;
bottom: 15px !important;
} */
/* dark mode styles */
.dark .ql-stroke {
  stroke: white;
}

.dark .ql-editor * {
  color: white;
}

.dark .ql-header * {
  color: white;
}

.dark .MuiTabs-indicator {
  background-color: white;
  color: white;
}

.dark .ant-picker * {
  color: rgb(202, 202, 202);
}

.ql-mention-list-container {
  max-height: 250px;
}

span.emoji {
  font-size: 20px;
}

span.quill-variable {
  background-color: yellow;
  padding: 2px;
  border-radius: 2px;
}

/* Removing contenteditable outline */
[contenteditable] {
  outline: 0px solid transparent;
}

.custom-input-file::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 0 15px;
  height: 100%;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.dangerous-html-container a {
  color: blue;
}

.dangerous-html-container a:hover {
  text-decoration: underline;
}

.popup-animation {
  animation: popup-scale 300ms ease-in-out;
}

.transform-origin_bottom-left {
  transform-origin: bottom left;
}

@keyframes popup-scale {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.dark span.quill-variable {
  background-color: #1c1c1c;
}

.dark .ql-toolbar.ql-snow button {
  color: #fff;
}

.dark .ql-toolbar.ql-snow svg .ql-stroke {
  stroke: currentColor;
}

.dark .ql-toolbar.ql-snow svg .ql-fill {
  fill: currentColor;
}

.dark .ql-editor.ql-blank::before {
  color: #ccc;
}

.dark .dangerous-html-container a {
  color: rgb(0, 89, 255) !important;
}

.dark .ql-mention-list-container {
  background-color: #282c34;
  border-color: #282c34;
}

.dark .ql-mention-list-item {
  color: #ccc;
}

.dark .ql-mention-list-item.selected {
  background-color: #1c1c1c;
  color: #fff;
}

.dark div.rbc-header,
.dark div.rbc-time-slot,
.dark div.rbc-label {
  color: #fff;
  background-color: #1c1c1c;
}

.dark div.rbc-day-bg:not(.rbc-off-range-bg) {
  background-color: #27272a !important;
}

.dark div.rbc-day-bg.rbc-off-range-bg {
  background-color: #424242 !important;
}

.dark button.rbc-button-link {
  color: #fff;
}

.dark button.rbc-button-link:hover {
  color: #000;
}

.dark .react-datepicker *:not(.react-datepicker__day--outside-month) {
  background-color: #1c1c1c;
  color: #fff;
}

.dark .react-datepicker .react-datepicker__day--outside-month {
  color: #7a7a7a;
}

.dark .dangerous-html-container p {
  color: #ccc;
}

.dark
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #2a2a2a;
}

.dark .intl-tel-input .selected-flag {
  color: white;
}

.dark .intl-tel-input .country-list {
  background-color: #1c1c1c;
  border: 1px solid #27272a;
}

.dark .intl-tel-input .country-list > li.country {
  color: white;
}

.dark .ql-snow .ql-picker-options {
  background-color: #1c1c1c;
}
.dark p > span.mention {
  background: #1c1c1c;
  color: #ccc;
}

.dark .ant-picker-panel-container {
  background-color: #1c1c1c;
}

.dark .ant-picker-header button,
.dark .ant-picker-content td {
  color: rgba(256, 256, 256, 0.75);
}

.dark .ant-picker-header-view button,
.dark .ant-picker-content th {
  color: #fff;
}

.dark .ant-picker-week-panel-row:hover td {
  background-color: #27272a;
}
