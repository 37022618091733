.customTooltip {
  position: fixed;
  z-index: 9999;
  transform: translateX(-50%);
  animation: popup 0.25s ease-in-out;
  transform-origin: bottom;
  margin: 0 !important;
}

.customTooltip::after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.customTooltip__top::after {
  top: 100%;
}

.customTooltip__bottom::after {
  top: -9px;
  transform: rotate(180deg);
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}
