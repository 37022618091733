.editor {
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
}

.editor .ql-toolbar.ql-snow {
  z-index: 99;
  white-space: nowrap;
  width: 175px;
}

.editor .ql-editor.ql-blank::before {
  color: #fff;
}

.toolbar {
  width: 100% !important;
  border: none !important;
  border-radius: 5px;
}

.toolbarBtns button {
  padding: 2px !important;
  width: 26px !important;
  color: #5b5757;
}

.toolbarBtns button:hover {
  color: #333 !important;
}

.sendMessageBtn {
  width: 35px !important;
  height: 35px !important;
  background: var(--color-primary) !important;
  color: #fff;
  border-radius: 50%;
  font-size: 17px;
  cursor: pointer;
  opacity: 0.8;
}

.sendMessageBtn:hover {
  opacity: 1;
  color: #fff !important;
}

.sendMessageBtn:focused {
  opacity: 1;
  color: #fff !important;
}

.sendMessageBtn:disabled {
  opacity: 0.7;
  cursor: default;
}
